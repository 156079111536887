/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { listAllFactory } from "utils/query";
import { API_ROUTES } from "routes";
import { ListResponse } from "./types";

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum USER_TYPE {
  ADMIN = "admin",
  VALIDATOR = "validator",
  CORPORATE = "corporate",
  REGULAR = "regular",
  MAINTAINER = "maintainer",
  MANAGER = "corporate_manager",
}

export type ListUserParams = {
  page: number;
  page_size: number;
  //   id__in: string[];
  search?: string;
  ordering?: string;
  hr?: string;
  type?: USER_TYPE;
};
export type ListUserItem = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  type: USER_TYPE;
  corporation: string | null;
  organization: string | null;
  date_joined: number;
  last_login: null | number;
};
export const listUsers = async (params?: ListUserParams): Promise<ListResponse<ListUserItem>> => {
  const { data } = await axios.get<ListResponse<ListUserItem>>("/api/users/", { params });
  return data;
};

export const listAllUsers = listAllFactory(listUsers);

type DetailUserParams = {
  id: string;
};
export type DetailUserItem = ListUserItem;
export const detailUser = async ({ id }: DetailUserParams): Promise<DetailUserItem> => {
  const { data } = await axios.get<DetailUserItem>(`/api/users/${id}/`);
  return data;
};
export type UpdateUserParams = {
  id: string;
  params: Partial<
    Pick<
      DetailUserItem,
      "username" | "first_name" | "last_name" | "email" | "type" | "corporation"
    > & {
      password: string;
    }
  >;
};

export type DetailRegistrationItem = {
  id: string;
  username: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  type: string;
  date_joined: string;
  last_login: string;
};
export type RegisterParams = Pick<
  DetailRegistrationItem,
  "username" | "first_name" | "last_name" | "email"
> & {
  password: string;
  type?: USER_TYPE;
};

export const updateUser = async ({ id, params }: UpdateUserParams): Promise<DetailUserItem> => {
  const { data } = await axios.patch<DetailUserItem>(`/api/users/${id}/`, params);
  return data;
};

export const registration = async (params: RegisterParams): Promise<DetailRegistrationItem> => {
  const { data } = await axios.post<DetailRegistrationItem>(API_ROUTES.REGISTER_USER, params);
  return data;
};

export const deleteUser = async (id: string): Promise<void> => {
  await axios.delete(`/api/users/${id}/`);
};

export type DetailCorporationsParams = {
  name__icontains: string;
};

export type DetailCorporateItem = {
  id: string;
  name: string;
  devices: string[];
};

export const detailCorporation = async (
  params: DetailCorporationsParams
): Promise<ListResponse<DetailCorporateItem>> => {
  const { data } = await axios.get<ListResponse<DetailCorporateItem>>("/api/corporations", {
    params,
  });
  return data;
};

export const exportUsers = async (search: string): Promise<Blob> => {
  const { data } = await axios.get(`/api/users/export`, {
    params: { search },
    responseType: "blob",
  });
  return data;
};

export type UserManagementType = {
  id: string;
  email: string;
  username: string;
  type: string;
  corporation: string;
};

export type UserAllDeviceDetail = {
  user: UserManagementType;
  user_dev: string[];
  corp_dev: string[];
};

export const getUserAllDevice = async (id: string): Promise<UserAllDeviceDetail> => {
  const { data } = await axios.get<UserAllDeviceDetail>(`/api/users/user-all-devices`, {
    params: { id },
  });
  return data;
};

export type UpdateUserDevice = Pick<UserAllDeviceDetail, "user"> & {
  remove_from_user: string[];
  add_to_user: string[];
  remove_from_corp: string[];
  add_to_corp: string[];
};

export type UpdateUserDeviceItem = {
  user: {
    create: string[];
    delete: number;
  };
  corp: {
    create: string[];
    delete: number;
  };
};

export const UpdateUserAllDevice = async (
  params: UpdateUserDevice
): Promise<UpdateUserDeviceItem> => {
  const { data } = await axios.patch<UpdateUserDeviceItem>(`/api/users/user-devices`, params);
  return data;
};
