import { Box, Card, TextField, Typography, Button, IconButton, useMediaQuery, Theme, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { useCallback, useRef } from "react";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteUserDialog from './DeleteUserDialog';

type UserTableProps = {
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  listQuery: any;
  processedData: any[];
  onLoadMore: () => void;
  totalCount: number;
  onEditUser: (user: any) => void;
  onUserPasswordReset: (user: any) => void;
  handleDeleteClick: (userId: string, username: string) => void;
  showDeleteConfirm: boolean;
  handleConfirmDelete: () => void;
  handleCancelDelete: () => void;
  isDeleting: boolean;
  userToDelete: { id: string; username: string } | null;
};

const UserTable: React.FC<UserTableProps> = ({
  onNameChange,
  listQuery,
  processedData,
  onLoadMore,
  totalCount,
  onEditUser,
  onUserPasswordReset,
  handleDeleteClick,
  showDeleteConfirm,
  handleConfirmDelete,
  handleCancelDelete,
  isDeleting,
  userToDelete,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    
    if (scrollHeight - scrollTop - clientHeight < 100) {
      if (processedData.length < totalCount) {
        onLoadMore();
      }
    }
  }, [processedData.length, totalCount, onLoadMore]);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const columns: GridColDef[] = [
    { field: 'username', headerName: 'USERNAME', flex: 1 },
    { field: 'type', headerName: 'TYPE', flex: 1 },
    { field: 'name', headerName: 'NAME', flex: 1 },
    { field: 'corporations', headerName: 'CORPORATION', flex: 1 },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      flex: 0.5,
      minWidth: 130,
      sortable: false,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Tooltip title="Edit User" arrow>
            <IconButton
              size="small"
              onClick={() => onEditUser(params.row)}
              sx={{ 
                color: '#27382C',
                border: '1px solid rgba(39, 56, 44, 0.2)',
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                padding: '4px',
                '&:hover': {
                  border: '1px solid #27382C',
                  backgroundColor: 'rgba(39, 56, 44, 0.2)'
                }
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset Password" arrow>
            <IconButton
              size="small"
              onClick={() => onUserPasswordReset(params.row)}
              sx={{ 
                color: '#27382C',
                border: '1px solid rgba(39, 56, 44, 0.2)',
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                padding: '4px',
                '&:hover': {
                  border: '1px solid #27382C',
                  backgroundColor: 'rgba(39, 56, 44, 0.2)'
                }
              }}
            >
              <LockResetIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete User" arrow>
            <IconButton
              size="small"
              onClick={() => handleDeleteClick(params.row.id, params.row.username)}
              sx={{ 
                color: '#AB0B0B',
                border: '1px solid rgba(171, 11, 11, 0.2)',
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
                padding: '4px',
                '&:hover': {
                  border: '1px solid #AB0B0B',
                  backgroundColor: 'rgba(171, 11, 11, 0.2)'
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    }
  ];

  const table = (
    <Box sx={{ overflow: 'hidden', height: "75vh" }}>
      {/* Header */}
      <Box sx={{ 
        height: 68, 
        backgroundColor: "#FFFFFF",
        display: 'flex',
        alignItems: 'center',
        pl: 5,
      }}>
        <Typography 
          variant="body1"
          fontWeight="bold"
          fontSize="18px"
          sx={{ color: "#27382C" }}
        >
          Users
        </Typography>
      </Box>

      {/* Scrollable content */}
      <Box 
        ref={scrollRef}
        onScroll={handleScroll}
        sx={{ 
          overflowY: 'auto', 
          maxHeight: 'calc(75vh - 69px)',
          '& .MuiDataGrid-main': {
            overflow: 'unset',
          },
        }}
      >
        <DataGrid
          sx={{
            fontFamily: 'Lexend',
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none",
            },
            width: "100%",
            height: "100% !important",
            backgroundColor: "white",
            borderRadius: 0,
            border: "none",
            px: 4,
            '& .MuiDataGrid-columnHeaders': {
              bgcolor: '#FFFFFF',
              borderBottom: '1px solid #D4D7D5',
              '& .MuiDataGrid-columnHeaderTitle': {
                color: "#D4D7D5",
                fontWeight: "bold",
                fontSize: "14px",
              }
            },
            '& .MuiDataGrid-columnHeader': {
              borderTop: 'none',
              borderRight: 'none',
            },
            '& .MuiDataGrid-footerContainer': {
              display: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              marginTop: '0 !important',
            },
          }}
          columnVisibilityModel={{
            id: false,
            email: false,
            last_login: false,
            date_joined: false,
            first_name: false,
            last_name: false,
            joined: false,
            corporation_id: false,
            ...(isSmallScreen && {
              name: false,
              type: false,
              corporations: false,
            }),
            ...(isMediumScreen && {
              name: false,
            }),
          }}
          rows={processedData}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
        />

        {/* Loading indicator */}
        {listQuery.isFetching && (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            p: 2,
            borderTop: '1px solid #D4D7D5'
          }}>
            <Typography variant="body2" color="text.secondary">
              Loading more...
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: "flex", flexFlow: "column", width: "100%", gap: 2}}>
        <Box sx={{ width: "400px", pl: 1 }}>
          <TextField
            sx={{ width: "100%" }}
            label="Search..."
            variant="outlined"
            size="small"
            onChange={onNameChange}
          />
        </Box>
        <Card sx={{ borderRadius: 6, overflow: 'hidden', bgcolor: '#FFFFFF' }}>
          {columns.length > 0 && table}
        </Card>
      </Box>

      <DeleteUserDialog
        open={showDeleteConfirm}
        username={userToDelete?.username ?? ''}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default UserTable;
