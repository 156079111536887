import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { UseQueryResult, useMutation } from "@tanstack/react-query";
import {
  ListFirmwareItem,
  boardMajorMinorPatch,
  deleteFirmware,
  downloadFirmware,
} from "queries/firmwares";
import { ListResponse } from "queries/types";

type FmListProps = {
  activeFw: UseQueryResult<ListResponse<ListFirmwareItem>, unknown>;
  inActiveFw: UseQueryResult<ListResponse<ListFirmwareItem>, unknown>;
};

const FWList = ({ activeFw, inActiveFw }: FmListProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const downloadMutation = useMutation({
    mutationFn: downloadFirmware,
  });

  const mutationDeleteFw = useMutation({
    mutationFn: deleteFirmware,
    onSuccess: () => {
      activeFw.refetch();
      inActiveFw.refetch();
    },
  });

  const genLi = (
    data: ListResponse<ListFirmwareItem> | undefined,
    label: string,
    deleteEnabled = true
  ) => {
    const filteredData = data?.data.filter((v) =>
      v.filename.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <Box sx={{ padding: 2, marginBottom: 2, border: "1px solid #e0e0e0", borderRadius: "4px" }}>
        <Typography gutterBottom sx={{ fontWeight: "bold" }}>
          {label}
        </Typography>
        <Divider />
        <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
          <Typography variant="body1" sx={{ flex: 2 }}>
            Filename
          </Typography>
          <Typography
            variant="body1"
            sx={{ flex: 1, textAlign: "right", display: { xs: "none", sm: "block" } }}
          >
            Version
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ maxHeight: 200, overflow: "auto", marginTop: 1 }}>
          {filteredData?.map((v) => (
            <Box
              key={v.id}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "5px",
                },
              }}
            >
              <Button
                sx={{
                  padding: 0,
                  margin: 0,
                  minWidth: 0,
                }}
                onClick={async () => {
                  const csvFile = await downloadMutation.mutateAsync({
                    id: boardMajorMinorPatch(v),
                  });
                  const csvURL = URL.createObjectURL(csvFile);
                  const tempLink = document.createElement("a");
                  tempLink.href = csvURL;
                  tempLink.setAttribute("download", v.filename);
                  tempLink.click();
                }}
              >
                <Tooltip title="Download firmware" arrow>
                  <DownloadForOfflineIcon />
                </Tooltip>
              </Button>
              <Typography variant="body2" sx={{ flex: 1, marginLeft: 2 }}>
                {v.filename}
              </Typography>
              <Typography
                variant="body2"
                sx={{ flex: 1, textAlign: "right", display: { xs: "none", sm: "block" } }}
              >
                {boardMajorMinorPatch(v)}
              </Typography>
              {deleteEnabled && (
                <Button
                  onClick={() => {
                    mutationDeleteFw.mutate(v);
                  }}
                  sx={{ padding: 0, margin: 0, minWidth: 0, marginLeft: 1 }}
                >
                  <Tooltip title="Deactivate firmware" arrow>
                    <DeleteIcon />
                  </Tooltip>
                </Button>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  let activeFirmwareList = <Typography>Loading ...</Typography>;
  if (activeFw.isFetched) {
    activeFirmwareList = genLi(activeFw.data, "Active Firmwares");
  }

  let inActiveFirmwareList = <Typography>Loading ...</Typography>;
  if (inActiveFw.isFetched) {
    inActiveFirmwareList = genLi(inActiveFw.data, "Inactive Firmwares", false);
  }

  return (
    <Card
      sx={{
        flex: 2,
        padding: 3,
        margin: "20px auto",
        maxWidth: "800px",
        borderRadius: "10px",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Firmware Management
      </Typography>
      <TextField
        fullWidth
        placeholder="Search firmware by filename..."
        variant="outlined"
        sx={{ marginBottom: 3 }}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {activeFirmwareList}
      {inActiveFirmwareList}
    </Card>
  );
};

export default FWList;
