import React from "react";
import { Container, Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { listAllFirmwares } from "queries/firmwares";
import FWList from "./listFw";
import FwUpload from "./uploadFw";

const FirmwaresMain = () => {
  const listActiveFw = useQuery({
    queryFn: () => listAllFirmwares({ page_size: 100 }),
    queryKey: ["listFirmwares"],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const listInActiveFw = useQuery({
    queryFn: () => listAllFirmwares({ active: false, page_size: 100 }),
    queryKey: ["listInactiveFirmwares"],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  let fwList = listActiveFw.data?.data.flatMap((v) => v) || [];
  fwList = fwList.concat(...(listInActiveFw.data?.data.flatMap((v) => v) || []));

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 2,
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
        }}
      >
        <FwUpload
          listActiveFw={fwList}
          refetch={{ active: listActiveFw.refetch, inactive: listInActiveFw.refetch }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "100%" },
        }}
      >
        <FWList activeFw={listActiveFw} inActiveFw={listInActiveFw} />
      </Box>
    </Container>
  );
};

export default FirmwaresMain;
