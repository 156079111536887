import React, { useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useMutation, QueryObserverResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  ListFirmwareItem,
  boardMajorMinorPatch,
  updateFirmware,
  uploadFirmware,
  FirmwareNameAndVersions,
} from "queries/firmwares";
import { ListResponse } from "queries/types";
import { waitCrc } from "utils/hash";

type FwUploadProps = {
  refetch: {
    active: () => Promise<QueryObserverResult<ListResponse<ListFirmwareItem>, unknown>>;
    inactive: () => Promise<QueryObserverResult<ListResponse<ListFirmwareItem>, unknown>>;
  };
  listActiveFw: ListFirmwareItem[];
};

const FwUpload = ({ refetch, listActiveFw }: FwUploadProps) => {
  const [file, setFile] = React.useState<File | null>(null);
  const [fileCrc32, setFileCrc32] = React.useState("Loading...");
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<FirmwareNameAndVersions & { mutationError: string; is_compressed: boolean }>({});

  const mutationUploadFw = useMutation({
    mutationFn: uploadFirmware,
    onSuccess: () => {
      setSuccessMessage("Firmware uploaded successfully!");
      refetch.active();
      refetch.inactive();
    },
    onError: (e: AxiosError<{ reason: object }>) => {
      setError("mutationError", { message: JSON.stringify(e.response?.data.reason, null, 1) });
    },
  });

  const mutationUpdateFw = useMutation({
    mutationFn: updateFirmware,
    onSuccess: () => {
      setSuccessMessage("Firmware updated successfully!");
      refetch.active();
      refetch.inactive();
    },
    onError: (e: AxiosError<{ reason: object }>) => {
      setError("mutationError", { message: JSON.stringify(e.response?.data.reason, null, 1) });
    },
  });

  const onSubmit = (data: FirmwareNameAndVersions) => {
    if (!file) return;

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append("file", file);

    const isFwPatch = listActiveFw.find(
      (fw) => boardMajorMinorPatch(fw) === boardMajorMinorPatch(data)
    );

    if (isFwPatch) {
      mutationUpdateFw.mutate({ id: isFwPatch.id, data: formData });
    } else {
      mutationUploadFw.mutate(formData);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);

      const regex = /^([a-zA-Z0-9]+)_([0-9]+)_([0-9]+)_([0-9]+)_(.+)\.([a-zA-Z0-9]+)$/;
      const match = selectedFile.name.match(regex);

      if (match) {
        const [, boardType, major, minor, patch, , extension] = match;

        setValue("filename", selectedFile.name);
        setValue("board_type", boardType);
        setValue("major_version", major);
        setValue("minor_version", minor);
        setValue("patch_version", patch);
        setValue("is_compressed", extension !== "bin");
      }
    }
  };

  useEffect(() => {
    if (file) {
      waitCrc(file).then(setFileCrc32);
    }
  }, [file]);

  const FileDetails = (
    <Box>
      {file && (
        <section>
          File details:
          <ul>
            <li>Name: {file.name}</li>
            <li>Type: {file.type}</li>
            <li>Size: {file.size} bytes</li>
            <li>Crc32: {fileCrc32} </li>
          </ul>
        </section>
      )}
    </Box>
  );

  return (
    <Card
      sx={{
        flex: 1,
        padding: 3,
        margin: "20px auto",
        maxWidth: "800px",
        borderRadius: "10px",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      }}
    >
      <Typography variant="h6">Upload Firmware</Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Select Firmware File
          </Typography>
          <input type="file" accept=".bin,.zip" onChange={handleFileChange} />
          {file && (
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              {FileDetails}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Filename"
            variant="outlined"
            fullWidth
            {...register("filename", { required: true })}
            error={!!errors.filename}
            helperText={errors.filename && "Filename is required"}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Board Type"
            variant="outlined"
            fullWidth
            {...register("board_type", { required: true })}
            error={!!errors.board_type}
            helperText={errors.board_type && "Board type is required"}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Major Version"
            type="number"
            variant="outlined"
            fullWidth
            {...register("major_version", { required: true })}
            error={!!errors.major_version}
            helperText={errors.major_version && "Major version is required"}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Minor Version"
            type="number"
            variant="outlined"
            fullWidth
            {...register("minor_version", { required: true })}
            error={!!errors.minor_version}
            helperText={errors.minor_version && "Minor version is required"}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Patch Version"
            type="number"
            variant="outlined"
            fullWidth
            {...register("patch_version", { required: true })}
            error={!!errors.patch_version}
            helperText={errors.patch_version && "Patch version is required"}
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            control={<Checkbox {...register("is_compressed")} defaultChecked={false} />}
            label="Is Compressed"
          />
        </Box>
        <Divider />
        <LoadingButton
          variant="contained"
          type="submit"
          loading={mutationUploadFw.isLoading || mutationUpdateFw.isLoading}
          sx={{ alignSelf: "flex-end" }}
        >
          {listActiveFw.find((fw) => boardMajorMinorPatch(fw) === boardMajorMinorPatch(watch()))
            ? "Patch Firmware"
            : "Upload Firmware"}
        </LoadingButton>
        {errors.mutationError && (
          <Snackbar
            open={!!errors.mutationError}
            autoHideDuration={6000}
            onClose={() => clearErrors("mutationError")}
          >
            <Alert severity="error">{errors.mutationError?.message}</Alert>
          </Snackbar>
        )}
        {successMessage && (
          <Snackbar
            open={!!successMessage}
            autoHideDuration={6000}
            onClose={() => setSuccessMessage(null)}
          >
            <Alert severity="success">{successMessage}</Alert>
          </Snackbar>
        )}
      </Box>
    </Card>
  );
};

export default FwUpload;
