import axios from "axios";
import type {
  DeviceDetailedStatus,
  DeviceGeneralStatus,
  DeviceStatus,
} from "utils/device-statuses";
import { USER_TYPE } from "queries/users";
import { ListResponse } from "../types";

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum DEVICE_TYPE {
  "Premium" = "premium",
  "Mini" = "mini",
}

export const DEVICE_ORDERING_FIELDS = [
  {
    value: "-status_needs_approval,smapp_id",
    label: "Default",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "type",
    label: "Type",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "name",
    label: "Name",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "smapp_id",
    label: "Trap ID",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "imei",
    label: "IMEI",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "mac",
    label: "MAC",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "imei",
    label: "Unique ID",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE],
  },
  {
    value: "imsi",
    label: "IMSI",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "iccid",
    label: "ICCID",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "country",
    label: "Country",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "city",
    label: "City",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "timezone",
    label: "Timezone",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "created_at",
    label: "Created at",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "updated_at",
    label: "Updated at",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "comment",
    label: "Comment",
    userType: [USER_TYPE.ADMIN],
  },
] as const;

export type ListDeviceParams = {
  search?: string;
  hr?: any;
  page: number;
  page_size: number;
  paginator?: string;
  active?: boolean;
  id__in?: string[];
  id__icontains?: string;
  type?: DEVICE_TYPE;
  type__in?: DEVICE_TYPE[];
  smapp_id__icontains?: string;
  smapp_id__in?: string[];
  imei__icontains?: string;
  mac__icontains?: string;
  imsi__icontains?: string;
  iccid__icontains?: string;
  comment__icontains?: string;
  country__icontains?: string;
  ordering?: (typeof DEVICE_ORDERING_FIELDS)[number]["value"];
  status__in?: DeviceDetailedStatus[];
  validator__id?: string;
  validator_isnull?: boolean;
  corporation_isnull?: boolean;
  corporation__id?: string;
};
export type ListDeviceItem = {
  id: string;
  current_crop: null | string;
  current_pest: null | string;
  last_measurement: null | string;
  last_geo_point: null | string;
  last_image: null | string;
  current_commission_status: null | string;
  current_config: null | string;
  current_firmware: null | string;
  last_network_diagnostic: null | string;
  active_operation_requests: string[];
  created_at: number;
  most_relevant_geo_point: {
    longitude: number;
    latitude: number;
  } | null;
  updated_at: number;
  corporations: string[] | null;
  corporation?: {
    id: string;
    name: string;
  } | null;
  comment: null | string;
  type: DEVICE_TYPE;
  hardware_version_string: null | string;
  name: null | string;
  sid: number;
  smapp_id: string;
  imei: string;
  mac: string;
  imsi: string;
  iccid: string;
  country: string | null;
  city: string | null;
  timezone: string;
  target_config: string;
  // target_config: null | string;
  target_firmware: string;
  last_summed_detection_count: number;
  last_summed_detection_count_delta: number;
  orchard: string;
  pest_name: string;
  crop_name: string;
  lure_type: string | null;
  lure_days_life_span: number | null;
  lure_last_replaced: string | null;
  lure_days_life_remaining: number | null;
  target_lure_last_replaced: string | null;

  // all users get status and status_general. This one might not be the latest status (if change was not approved by admin)
  status: DeviceDetailedStatus;
  status_general: DeviceGeneralStatus;
  // only for admins - last status, which might be waiting for approval
  last_status: DeviceStatus | null;

  // used for orgs devices view
  corporation_name: string | null;
  validation_start_date: number | null;
};

export const listDevices = async (
  params?: ListDeviceParams
): Promise<ListResponse<ListDeviceItem>> => {
  const { data } = await axios.get<ListResponse<ListDeviceItem>>("/api/devices/", { params });
  return data;
};

export const listDevicesV2 = async (params?: ListDeviceParams): Promise<ListDeviceItem[]> => {
  const { data } = await axios.get<ListDeviceItem[]>("/api/devices/", { params });
  return data;
};

// TODO: check this
export type DetailDeviceItem = ListDeviceItem;
type DetailDeviceParams = {
  id: string;
};
export const detailDevice = async ({ id }: DetailDeviceParams): Promise<DetailDeviceItem> => {
  const { data } = await axios.get<DetailDeviceItem>(`/api/devices/${id}`);
  return data;
};

export type UpdateDevicePartialParams = Partial<
  Pick<ListDeviceItem, "name" | "comment" | "country" | "city" | "timezone"> & {
    target_lure_type: string | null;
    target_lure_days_life_span: number | null;
    target_lure_last_replaced: string | null;
    lure_last_replaced: string | null;

    target_crop: string | null;
    target_pest: string | null;
    target_config: string | null;
    target_operation_request: string | null;
    target_firmware: string | null;
    // only detailed status can be updated
    status: {
      detailed_status: DeviceDetailedStatus;
    };
    validator: string | null;
    validation_start_date?: number | null;
  }
>;

export type UpdateValidatorDevicesPartialParams = Partial<{
  add_device_ids?: Array<string>;
  remove_device_ids?: Array<string>;
}>;

export type UpdateDeviceParams = {
  id: string;
  params: UpdateDevicePartialParams;
};
export const updateDevice = async ({
  id,
  params,
}: UpdateDeviceParams): Promise<DetailDeviceItem> => {
  const { data } = await axios.patch<DetailDeviceItem>(`/api/devices/${id}/`, params);
  return data;
};

export type UpdateValidatorDevicesParams = {
  id: string;
  params: UpdateValidatorDevicesPartialParams;
};
export const updateValidatorDevices = async ({
  id,
  params,
}: UpdateValidatorDevicesParams): Promise<ListDeviceItem> => {
  const { data } = await axios.patch<DetailDeviceItem>(`/api/validators/${id}/`, params);
  return data;
};

export type UpdateDeviceStatusParams = {
  id: string;
  status: DeviceDetailedStatus;
};

export const updateDeviceStatus = async ({
  id,
  status,
}: UpdateDeviceStatusParams): Promise<void> => {
  const { data } = await axios.patch<void>(`/api/devices/${id}/update-status/`, { status });
  return data;
};

export const approveStatusChange = async ({ deviceId }: { deviceId: string }): Promise<void> => {
  await axios.patch(`/api/devices/${deviceId}/approve-status-change/`, {});
};

export type DeviceStatusSummary = {
  total_devices: number;
  general: Array<{
    status_general: DeviceGeneralStatus;
    number: number;
  }>;
  detailed: Array<{
    status_detailed: DeviceDetailedStatus;
    number: number;
  }>;
};

type DeviceStatusSummaryParams = {
  search?: string;
  corporation__id?: string;
};

export const getDeviceStatusSummary = async (
  params?: DeviceStatusSummaryParams
): Promise<DeviceStatusSummary> => {
  const { data } = await axios.get<DeviceStatusSummary>("api/devices/status-summary/", { params });
  return data;
};
