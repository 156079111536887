import React, { useState } from "react";
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Container,
  Box,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  Alert,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "context/AuthProvider";
import { RegisterParams } from "queries/users";
import { Link } from "react-router-dom";
import ROUTES from "routes";

import { ReactComponent as LogoHBlack } from "icons/logo-h-black.svg";
import PasswordAdornment from "components/PasswordAdornment";

const schema = Yup.object({
  username: Yup.string().required("Username is required "),
  password: Yup.string().required("Password is required"),
  email: Yup.string().email().required("Email is required"),
  terms: Yup.boolean().oneOf([true]),
});

const RegistrationForm: React.FC = () => {
  const { registerUser, isRegistrationInProgress, authError, registerError } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterParams & { terms: boolean }>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<RegisterParams & { terms: boolean }> = (data) => {
    const { terms, ...registrationData } = data;
    registerUser(registrationData);
  };

  return (
    <Container
      component="form"
      sx={{
        width: "33ch",
        background: "white",
        p: 5,
        borderRadius: 8,
        boxShadow: "2",
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box sx={{ width: "80%", margin: "0 auto 20px auto" }}>
        <LogoHBlack />
      </Box>
      <TextField
        required
        fullWidth
        size="small"
        margin="normal"
        label="Username"
        type="text"
        {...register("username")}
        error={!!errors.username}
        helperText={errors.username?.message}
      />
      <TextField
        type={showPassword ? "text" : "password"}
        required
        fullWidth
        size="small"
        margin="normal"
        label="Password"
        {...register("password")}
        error={!!errors.password}
        helperText={errors.password?.message}
        InputProps={{
          endAdornment: (
            <PasswordAdornment
            showPassword={showPassword}
            onTogglePasswordVisibility={handleClickShowPassword}
          />
          ),
        }}
      />
      <TextField
        fullWidth
        size="small"
        margin="normal"
        label="First Name"
        type="text"
        {...register("first_name")}
        error={!!errors.first_name}
        helperText={errors.first_name?.message}
      />
      <TextField
        fullWidth
        size="small"
        margin="normal"
        label="Last Name"
        type="text"
        {...register("last_name")}
        error={!!errors.last_name}
        helperText={errors.last_name?.message}
      />
      <TextField
        required
        fullWidth
        size="small"
        margin="normal"
        label="Email"
        type="email"
        {...register("email")}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <FormControl error={!!errors.terms} component="fieldset" variant="standard">
        <FormControlLabel
          control={<Checkbox {...register("terms")} />}
          label={
            <span>
              Agree with{" "}
              <a
                href="/terms-and-conditions.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", fontWeight: "bold", color: "black" }}
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://scoutlabs.ag/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", fontWeight: "bold", color: "black" }}
              >
                Privacy Policy
              </a>
            </span>
          }
        />
        {errors.terms && (
          <FormHelperText error>
            You must agree to the Terms and Conditions and Privacy Policy
          </FormHelperText>
        )}
      </FormControl>
      <Box sx={{ mt: 2 }}>
        {authError !== null && <Alert severity="error">Failed to log in</Alert>}
      </Box>
      <Box sx={{ mt: 2 }}>
        {registerError !== null && (
          <Alert severity="error">
            {(() => {
              switch (registerError.response?.status) {
                case 400:
                  return "User already exists";
                case 500:
                  return "Server error, please try again later";
                default:
                  return "Registration error";
              }
            })()}
          </Alert>
        )}
      </Box>
      <LoadingButton
        loading={isRegistrationInProgress}
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        type="submit"
      >
        Register
      </LoadingButton>
      <Link to={`${ROUTES.LOGIN}`}>
        <Button variant="outlined" fullWidth sx={{ mt: 2 }}>
          Log in
        </Button>
      </Link>
    </Container>
  );
};

export default RegistrationForm;
