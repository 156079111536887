import React from "react";
import { Alert, Box, Button, TextField, Modal, Typography } from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import { AxiosError } from "axios";

interface ResetPasswordModalProps {
  open: boolean;
  onClose: () => void;
  methods: any;
  onSubmit: (data: any) => void;
  resetPasswordError: AxiosError | null;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  open,
  onClose,
  methods,
  onSubmit,
  resetPasswordError,
}) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="reset-password-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 4,
          minWidth: 400,
          maxWidth: 500,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Reset Password
        </Typography>
        {resetPasswordError !== null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {(() => {
              switch (resetPasswordError.response?.status) {
                case 500:
                  return "Server error, please try again later";
                default:
                  return "Password reset error";
              }
            })()}
          </Alert>
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Controller
              name="password1"
              control={methods.control}
              defaultValue=""
              rules={{
                required: "Password is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="New Password"
                  type="password"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  sx={{ mb: 2 }}
                />
              )}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Reset Password
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
