import React, { useState } from "react";
import { Stack, Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import ROUTES from "routes";
import { archiveDevices } from "queries/devices/archive";
import ArchiveDeviceDialog from "./ArchiveDeviceDialog";

type ButtonsOnHoverProps = {
    id: string;
    isAdmin: boolean;
    smapp_id: string;
    setErrorList?: React.Dispatch<React.SetStateAction<string[]>>;
    setSuccessList?: React.Dispatch<React.SetStateAction<string[]>>;
  };
  
const ButtonsOnHover: React.FC<ButtonsOnHoverProps> = ({
    id,
    smapp_id,
    isAdmin,
    setErrorList,
    setSuccessList
}) => {
    const [isHovered, setHovered] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isArchiving, setArchiving] = useState(false);

    const queryClient = useQueryClient();
    const archiveDeviceMutation = useMutation({
        mutationFn: archiveDevices,
        onSuccess: async () => {
        await queryClient.invalidateQueries({queryKey: ["inf-list-devices"]});
        setSuccessList?.((pre) => [...pre, "Archiving succeeded"]);
        },
        onError: () => {
        setErrorList?.((pre) => [...pre, "Archiving failed"]);
        setDialogOpen(false);
        },
        onSettled: () => {
        setArchiving(false);
        console.log("Archiving settled");
        },
    });

    const handleArchiveClick = () => {
        setDialogOpen(true);
    }

    const handleConfirm = () => {
        setArchiving(true);
        archiveDeviceMutation.mutate({
        devices_to_archive: [id]
        });
    }

    return (
        <>
        {!isHovered && (
            <Stack direction="row" spacing={1} alignItems="flex-start">
            <Button variant="outlined" size="small" onMouseEnter={() => setHovered(true)}>
                Actions
            </Button>
            </Stack>
        )}
        {isHovered && (
            <Stack direction="row" spacing={1} alignItems="flex-start" onMouseLeave={() => setHovered(false)}>
            <Button 
                variant="outlined" 
                size="small" 
                onClick={handleArchiveClick}
            >
                Archive
            </Button>
            {isAdmin && (
                <Link to={`${ROUTES.VALIDATOR_DEVICES}/${id}`}>
                <Button variant="outlined" size="small">
            Validate
                </Button>
                </Link>
            )}
            <Link to={`${ROUTES.DEVICES}/${id}`}>
                <Button variant="outlined" size="small">
                Open
                </Button>
            </Link>
            </Stack>
        )}
        <ArchiveDeviceDialog
            open={isDialogOpen}
            smapp_id={smapp_id}
            onClose={() => setDialogOpen(false)}
            onConfirm={handleConfirm}
            isArchiving={isArchiving}
        />
        </>

    )
}

export default ButtonsOnHover;