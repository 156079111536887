import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";
import negate from "lodash/negate";

import { useDrawer } from "context/DrawerProvider";
import { DEVICE_ORDERING_FIELDS, DEVICE_TYPE, ListDeviceParams } from "queries/devices";
import { ChangeHandler, Controller, useForm } from "react-hook-form";
import debounce from "lodash/debounce";
import * as Yup from "yup";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "context/AuthProvider";
import { USER_TYPE } from "queries/users";
import useImeiLabel from "hooks/useImeiLabel";
import useDeviceFilters from "hooks/useDeviceFilters";

const schema = Yup.object({
  ordering: Yup.string().required(),
});

const DEVICE_TYPE_OPTIONS = Object.entries(DEVICE_TYPE).map(([label, value]) => ({
  value,
  label,
}));

export type DeviceFilters = Partial<Omit<ListDeviceParams, "page" | "page_size">>;

const DeviceFiltersDrawer: React.FC = () => {
  const { isAdmin, currentUser } = useAuth();
  const imeiLabel = useImeiLabel();
  const { rightDrawer, setRightDrawer } = useDrawer();
  const theme = useTheme();

  const [focusedInputId, setFocusedInputId] = React.useState<keyof DeviceFilters | undefined>(
    undefined
  );

  const { filters, setFilters: setDeviceFilters, defaultFilters } = useDeviceFilters();

  const DEVICE_ORDERING_OPTIONS = React.useMemo(
    () =>
      DEVICE_ORDERING_FIELDS.filter((it) =>
        (it.userType as readonly USER_TYPE[]).includes(currentUser?.type ?? USER_TYPE.REGULAR)
      ).map((it) => ({
        label: it.label,
        value: it.value,
      })),

    [currentUser?.type]
  );

  const {
    register: rhfRegister,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<DeviceFilters>({
    resolver: yupResolver(schema),
    values: filters,
    defaultValues: defaultFilters,
  });

  const watchAllFields = watch();

  const labelShrinkMap = React.useMemo(() => {
    const values = mapValues(watchAllFields, negate(isEmpty));
    if (focusedInputId) {
      values[focusedInputId] = true;
    }
    return values;
  }, [watchAllFields, focusedInputId]);

  const register = React.useCallback(
    (id: keyof DeviceFilters) => {
      const { onBlur, ...rest } = rhfRegister(id);

      const handleFocus = () => {
        setFocusedInputId(id);
      };

      const handleBlur: ChangeHandler = (e) => {
        setFocusedInputId(undefined);
        return onBlur(e);
      };

      return {
        onBlur: handleBlur,
        onFocus: handleFocus,
        ...rest,
      };
    },
    [rhfRegister]
  );

  const debouncedSubmit = React.useMemo(
    () => debounce(handleSubmit(setDeviceFilters), 500),
    [handleSubmit, setDeviceFilters]
  );

  React.useEffect(() => {
    const subscription = watch(() => {
      debouncedSubmit();
    });
    return () => subscription.unsubscribe();
  }, [debouncedSubmit, watch]);

  return (
    <SwipeableDrawer
      anchor={theme.direction === "ltr" ? "right" : "left"}
      open={rightDrawer === "device-filters"}
      onClose={() => setRightDrawer(undefined)}
      onOpen={() => setRightDrawer("device-filters")}
    >
      <Box
        sx={{ width: 300, p: 2, flex: 1, display: "flex", flexDirection: "column" }}
        role="presentation"
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="overline">Device filters</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setRightDrawer(undefined)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              float: "right",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        <Box component="form" sx={{ flex: 1 }} noValidate autoComplete="off">
          {/* change uuid filter to charfilter */}
          {/* <TextField
            label="ID"
            error={!!errors.id__icontains}
            helperText={errors.id__icontains?.message}
            placeholder="Enter some text"
            fullWidth
            size="small"
            margin="normal"
            {...register("id__icontains")}
          /> */}
          <TextField
            label={imeiLabel}
            error={!!errors.imei__icontains}
            helperText={errors.imei__icontains?.message}
            InputLabelProps={{ shrink: labelShrinkMap.imei__icontains }}
            placeholder="Enter some text"
            fullWidth
            size="small"
            margin="normal"
            {...register("imei__icontains")}
          />
          <TextField
            label="MAC"
            error={!!errors.mac__icontains}
            helperText={errors.mac__icontains?.message}
            InputLabelProps={{ shrink: labelShrinkMap.mac__icontains }}
            placeholder="Enter some text"
            fullWidth
            size="small"
            margin="normal"
            {...register("mac__icontains")}
          />
          <TextField
            label="Trap ID"
            error={!!errors.smapp_id__icontains}
            helperText={errors.smapp_id__icontains?.message}
            InputLabelProps={{ shrink: labelShrinkMap.smapp_id__icontains }}
            placeholder="Enter some text"
            fullWidth
            size="small"
            margin="normal"
            {...register("smapp_id__icontains")}
          />
          {isAdmin && (
            <TextField
              label="IMSI"
              error={!!errors.imsi__icontains}
              helperText={errors.imsi__icontains?.message}
              InputLabelProps={{ shrink: labelShrinkMap.imsi__icontains }}
              placeholder="Enter some text"
              fullWidth
              size="small"
              margin="normal"
              {...register("imsi__icontains")}
            />
          )}
          {isAdmin && (
            <TextField
              label="ICCID"
              error={!!errors.iccid__icontains}
              helperText={errors.iccid__icontains?.message}
              InputLabelProps={{ shrink: labelShrinkMap.iccid__icontains }}
              placeholder="Enter some text"
              fullWidth
              size="small"
              margin="normal"
              {...register("iccid__icontains")}
            />
          )}
          <Controller
            control={control}
            name="type__in"
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <Autocomplete
                multiple
                disablePortal
                options={DEVICE_TYPE_OPTIONS}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: labelShrinkMap.type__in }}
                    label="Type"
                    margin="normal"
                    placeholder="Select options"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                ref={ref}
                value={DEVICE_TYPE_OPTIONS.filter((it) => value?.includes(it.value))}
                onBlur={() => {
                  setFocusedInputId(undefined);
                  onBlur();
                }}
                onFocus={() => setFocusedInputId("type__in")}
                onChange={(e, selected) => {
                  onChange(selected.map((it) => it.value));
                }}
              />
            )}
          />
          <TextField
            label="Country"
            error={!!errors.country__icontains}
            helperText={errors.country__icontains?.message}
            InputLabelProps={{ shrink: labelShrinkMap.country__icontains }}
            placeholder="Enter some text"
            fullWidth
            size="small"
            margin="normal"
            {...register("country__icontains")}
          />
          {isAdmin && (
            <TextField
              label="Comment"
              error={!!errors.comment__icontains}
              helperText={errors.comment__icontains?.message}
              InputLabelProps={{ shrink: labelShrinkMap.comment__icontains }}
              placeholder="Enter some text"
              fullWidth
              size="small"
              margin="normal"
              minRows={3}
              multiline
              {...register("comment__icontains")}
            />
          )}
          <Controller
            control={control}
            name="ordering"
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                options={DEVICE_ORDERING_OPTIONS}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: labelShrinkMap.ordering }}
                    label="Order by"
                    margin="normal"
                    placeholder="Select option"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                ref={ref}
                value={DEVICE_ORDERING_OPTIONS.find((it) => value === it.value) ?? null}
                onBlur={() => {
                  setFocusedInputId(undefined);
                  onBlur();
                }}
                onFocus={() => setFocusedInputId("ordering")}
                onChange={(e, selected) => {
                  onChange(selected?.value ?? null);
                }}
              />
            )}
          />
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            reset(defaultFilters);
          }}
        >
          Clear
        </Button>
      </Box>
    </SwipeableDrawer>
  );
};

export default DeviceFiltersDrawer;
